import React from 'react';

export const TextField = ({
	label,
	type,
	note,
	field,
	form,
	multiline,
	rows,
}) => {
	const { name, onBlur, onChange, value } = field;
	const { touched, errors } = form;
	const hasError = touched[name] && errors[name];
	const className = hasError ? 'form-control is-invalid' : 'form-control';

	return (
		<div className="form-group">
			<label className={hasError ? 'text-danger' : ''} htmlFor={name}>
				{label}
			</label>
			{multiline ? (
				<textarea
					className={className}
					rows={rows}
					id={name}
					onBlur={onBlur}
					onChange={onChange}
					value={value}
				/>
			) : (
				<input
					type={type}
					className={className}
					id={name}
					onBlur={onBlur}
					onChange={onChange}
					value={value}
				/>
			)}
			{hasError && <div className="invalid-feedback">{errors[name]}</div>}
			{note && <span style={{ fontSize: '11px' }}>{note}</span>}
		</div>
	);
};

TextField.defaultProps = {
	type: 'text',
	multiline: false,
	rows: 3,
};
