import React from 'react';

function Icon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="301"
			height="248"
			viewBox="0 0 301 248"
		>
			<g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
				<g stroke="#E5E5E5" strokeWidth="2">
					<g transform="translate(-759 -255) translate(760 256) translate(0 75)">
						<path strokeLinecap="round" d="M.5 153.698h298.196" />
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M137.825 29.147v-6.076a8.447 8.447 0 00-16.894 0v6.076M129 14.624V.545M154.547 153.582V73.569c0-13.901-11.269-25.17-25.169-25.17-13.902 0-25.168 11.269-25.168 25.17v80.013"
						/>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M148.225 56.894v-9.249c0-10.41-8.438-18.847-18.847-18.847-10.41 0-18.847 8.438-18.847 18.847v9.245"
						/>
						<path d="M139 101.155v3.49M139 112.42v3.49M139 123.685v3.49M129 101.155v3.49M129 112.42v3.49M129 123.685v3.49M119 101.155v3.49M119 112.42v3.49M119 123.685v3.49M139 89.89v3.49M129 89.89v3.49M119 89.89v3.49" />
						<path
							strokeLinecap="round"
							d="M165.196 77h-10.649M198 153.582V77.163M45 76.05v77.533M93 153.582V76.05"
						/>
						<path
							strokeLinecap="round"
							d="M86.8967831 70.9469713L86.8967831 57.4610358 51.9222692 57.4610358 51.9222692 70.9469713"
						/>
						<path
							strokeLinecap="round"
							d="M77.116 43.16H61.703a3.976 3.976 0 00-3.976 3.976v10.326h23.365V47.136a3.976 3.976 0 00-3.976-3.976zM64.272 43.16l5.138-11.93M74.547 43.16L69.41 31.23M69 31.23V19.967M45.5 96.635V75.146a4.2 4.2 0 014.2-4.199h9.534v23.317M79.585 94.264V70.947h9.535a4.2 4.2 0 014.2 4.2v21.488M93.32 77h10.89"
						/>
						<path d="M57.504 103h8.15M57.504 112h8.15M57.504 120h8.15M57.504 128h8.15M174.915 78h13.758M174.915 88h13.758M174.915 98h13.758M174.915 108h13.758M81.314 103h-8.15M81.314 112h-8.15M81.314 120h-8.15M81.314 128h-8.15" />
						<path
							strokeLinecap="round"
							d="M211 76.05v77.533M259 153.582V76.05"
						/>
						<path
							strokeLinecap="round"
							d="M252.896783 70.9469713L252.896783 57.4610358 217.922269 57.4610358 217.922269 70.9469713"
						/>
						<path
							strokeLinecap="round"
							d="M243.116 43.16h-15.413a3.976 3.976 0 00-3.976 3.976v10.326h23.365V47.136a3.976 3.976 0 00-3.976-3.976zM230.272 43.16l5.138-11.93M240.547 43.16l-5.137-11.93M235 31.23V19.967M211.5 96.635V75.146a4.2 4.2 0 014.2-4.199h9.534v23.317M245.585 94.264V70.947h9.535a4.2 4.2 0 014.2 4.2v21.488"
						/>
						<path d="M223.504 103h8.15M223.504 112h8.15M223.504 120h8.15M223.504 128h8.15M247.314 103h-8.15M247.314 112h-8.15M247.314 120h-8.15M247.314 128h-8.15" />
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M198.392 77.163c0-9.166-7.431-16.598-16.598-16.598-9.167 0-16.598 7.432-16.598 16.598M181 60.565v-8.467"
						/>
						<path strokeLinecap="round" d="M165 77.163v76.419" />
					</g>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M120.904 62.596c0-11.798-9.665-21.397-21.55-21.397h-.402l-.183-.372c-3.491-9.416-12.619-15.743-22.71-15.743-10.738 0-20.302 7.16-23.249 17.41l-.153.537-.54-.139a9.932 9.932 0 00-2.42-.283c-5.367 0-9.726 4.178-9.925 9.51l-.017.378-.353.132C33.18 55.03 29 61.108 29 67.759 29 76.716 36.327 84 45.333 84h7.07M297.528 26.912c0-8.317-6.786-15.083-15.131-15.083h-.282l-.128-.262C279.535 4.929 273.127.469 266.042.469c-7.54 0-14.255 5.047-16.324 12.273l-.107.378-.38-.098a6.947 6.947 0 00-1.7-.2 6.946 6.946 0 00-6.968 6.705l-.012.266-.248.093C235.936 21.579 233 25.863 233 30.55 233 36.866 238.144 42 244.468 42h4.963M203.818 49.07c0-6.576-5.45-11.926-12.15-11.926h-.227l-.103-.208c-1.969-5.248-7.115-8.775-12.804-8.775-6.055 0-11.448 3.99-13.109 9.705l-.086.299-.305-.078a5.661 5.661 0 00-1.364-.158c-3.026 0-5.484 2.33-5.596 5.301l-.01.211-.2.073c-3.507 1.34-5.864 4.726-5.864 8.433 0 4.993 4.131 9.053 9.209 9.053h3.986"
						transform="translate(-759 -255) translate(760 256)"
					/>
				</g>
				<path
					stroke="#2C1B6F"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M78.934 62.127h11.885c-.097 6.055-1.03 15.577-5.046 25.713"
					transform="translate(-759 -255) translate(760 256) translate(87 80.087)"
				/>
				<path
					fill="#FFF"
					stroke="#2C1B6F"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M99.515 165.5c0-7.475-6.06-13.534-13.534-13.534H51.177c-7.475 0-13.535 6.059-13.535 13.534h61.873zM85.084 143.772c-.919-1.407-1.959-2.33-2.901-2.934-2.897-1.86-7.998-4.88-7.998-10.314 0-6.96 3.609-10.06 3.609-10.06l-.269-6.23H59.632l-.27 6.23s3.61 3.1 3.61 10.06c0 5.434-5.102 8.454-7.998 10.314-.943.603-1.983 1.526-2.902 2.934-2.563 3.924-2.176 8.194-2.176 8.194H87.26s.388-4.27-2.176-8.195zM98.523 65.695h18.747a2.386 2.386 0 012.346 2.82c-1.342 7.333-6.898 26.437-28.392 26.437"
					transform="translate(-759 -255) translate(760 256) translate(87 80.087)"
				/>
				<path
					stroke="#2C1B6F"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M98.43 71.638h14.504s-1.848 7.8-8.075 12.942"
					transform="translate(-759 -255) translate(760 256) translate(87 80.087)"
				/>
				<path
					fill="#FFF"
					stroke="#2C1B6F"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M38.633 65.695H19.886a2.386 2.386 0 00-2.346 2.82c1.34 7.333 6.898 26.437 28.392 26.437"
					transform="translate(-759 -255) translate(760 256) translate(87 80.087)"
				/>
				<path
					stroke="#2C1B6F"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M38.727 71.638H24.224s1.848 7.8 8.075 12.942"
					transform="translate(-759 -255) translate(760 256) translate(87 80.087)"
				/>
				<path
					fill="#FFF"
					stroke="#2C1B6F"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M98.2 54.413H38.957S34.17 88.35 59.404 114.234h18.346C102.985 88.35 98.2 54.414 98.2 54.414z"
					transform="translate(-759 -255) translate(760 256) translate(87 80.087)"
				/>
				<path
					stroke="#2C1B6F"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M68 29.002V.214M83.28 31.266l5.869-15.191M44.024 37.788L24.382 15.735M56.013 31.266l-5.87-15.191M30.39 60.035l-30.092-5M35.125 47.779l-14.86-8.084M108.902 60.035l30.091-5M95.269 37.788l19.641-22.053M104.167 47.779l14.86-8.084"
					transform="translate(-759 -255) translate(760 256) translate(87 80.087)"
				/>
				<path
					stroke="#2C1B6F"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.5"
					d="M19.088 29.864a6.194 6.194 0 01-4.047-1.47 6.195 6.195 0 01-4.047 1.47c-1.788 0-3.561-.748-4.865-2.052a7.07 7.07 0 01-1.575-2.38 7.06 7.06 0 01-2.368-1.563A6.917 6.917 0 01.15 19.408a6.27 6.27 0 011.456-4.449A6.273 6.273 0 01.15 10.51a6.92 6.92 0 012.038-4.463 7.06 7.06 0 012.366-1.56A7.075 7.075 0 016.13 2.105C7.433.802 9.206.055 10.994.055c1.513 0 2.932.518 4.047 1.468A6.193 6.193 0 0119.087.055c1.789 0 3.561.747 4.865 2.05.7.7 1.23 1.513 1.576 2.382.874.35 1.685.88 2.366 1.561a6.92 6.92 0 012.04 4.462 6.276 6.276 0 01-1.458 4.45 6.273 6.273 0 011.457 4.448c-.102 1.666-.827 3.25-2.039 4.462a7.061 7.061 0 01-2.366 1.561 7.067 7.067 0 01-1.576 2.382c-1.303 1.303-3.076 2.05-4.864 2.05z"
					transform="translate(-759 -255) translate(760 256) translate(87 80.087) translate(54 64.64) translate(0 .082)"
				/>
				<path
					fill="#2FE4A8"
					d="M23.094 16.109c-1.77-.406-3.99-.832-5.725-1.15 1.736-.318 3.954-.744 5.725-1.15.703-.16 1.93-.548 2.463-1.081 1.06-1.06.948-2.892-.252-4.091-.875-.875-2.086-1.17-3.09-.852.316-1.004.023-2.216-.852-3.091-1.2-1.199-3.031-1.311-4.091-.25-.533.532-.92 1.76-1.081 2.462-.407 1.77-.832 3.989-1.15 5.725-.318-1.736-.744-3.955-1.15-5.725-.161-.703-.548-1.93-1.081-2.463-1.06-1.06-2.892-.948-4.091.251-.876.875-1.17 2.087-.853 3.09-1.004-.316-2.215-.022-3.09.853-1.2 1.199-1.312 3.03-.251 4.091.533.533 1.76.92 2.463 1.081 1.77.406 3.989.832 5.724 1.15-1.735.318-3.954.744-5.724 1.15-.703.16-1.93.548-2.463 1.081-1.06 1.06-.948 2.892.25 4.091.876.876 2.087 1.169 3.091.853-.316 1.003-.023 2.215.853 3.09 1.199 1.199 3.03 1.311 4.09.251.534-.533.921-1.76 1.082-2.463.406-1.77.832-3.989 1.15-5.725.318 1.736.743 3.954 1.15 5.725.16.703.548 1.93 1.08 2.463 1.061 1.06 2.893.948 4.092-.251.875-.875 1.168-2.087.852-3.09 1.004.316 2.215.023 3.09-.853 1.2-1.199 1.312-3.03.252-4.09-.533-.534-1.76-.921-2.463-1.082z"
					transform="translate(-759 -255) translate(760 256) translate(87 80.087) translate(54 64.64) translate(0 .082)"
				/>
			</g>
		</svg>
	);
}

export default Icon;
