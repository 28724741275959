import React from 'react';

export const CheckboxField = ({ label, type, field, form }) => {
	const { name, onBlur, onChange, value } = field;
	const { touched, errors } = form;
	const hasError = touched[name] && errors[name];
	const className = hasError
		? 'form-check-input is-invalid'
		: 'form-check-input';

	return (
		<div className="form-check">
			<input
				type={type}
				className={className}
				id={name}
				onBlur={onBlur}
				onChange={onChange}
				value={value}
			/>
			<label className={hasError ? 'text-danger' : ''} htmlFor={name}>
				{label}
			</label>
			{hasError && <div className="invalid-feedback">{errors[name]}</div>}
		</div>
	);
};

CheckboxField.defaultProps = {
	type: 'checkbox',
};
